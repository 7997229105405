// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-2TXlflo4kK0d_v3eznMnrQpPEg9H8Nk",
  authDomain: "union-ff2bd.firebaseapp.com",
  databaseURL: "https://union-ff2bd-default-rtdb.firebaseio.com",
  projectId: "union-ff2bd",
  storageBucket: "union-ff2bd.appspot.com",
  messagingSenderId: "211842378507",
  appId: "1:211842378507:web:d4dc011424b427147e100e",
  measurementId: "G-SBRCWSQXWW"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };